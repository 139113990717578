import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import LogoutFunction from "../services/LogoutFunction";

export const paymentPaymobApi = createAsyncThunk(
  "course/payment",
  async (data) => {
    const tokenData = JSON.parse(localStorage.getItem("userData")).token;

    let bodyReqiest;

    if (data.promocode) {
      bodyReqiest = {
        pay_type: data.pay_type,
        promo_code: data.promocode,
      };
    } else {
      bodyReqiest = {
        pay_type: data.pay_type,
      };
    }

    if (data.pay_type === "mob_wallet") {
      bodyReqiest = {
        ...bodyReqiest,
        mobile_wallet: data.mobile_wallet,
      };
    }

    const request = await fetch(
      `${process.env.REACT_APP_VERSEL_API}/api/students/payment/pay/${data.course_id}`,
      {
        method: "POST",
        headers: {
          Authorization: `Bearer ${tokenData}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(bodyReqiest),
      }
    )
      .then((response) => response.json())
      .then((resulet) => {
        return resulet;
      })
      .catch((error) => {
        return error;
      });
    return request;
  }
);

const paymentPaymobSlicer = createSlice({
  name: "payment/paymob",
  initialState: {
    loading: false,
    url: null,
    error: null,
  },
  extraReducers: (builder) => {
    builder.addCase(paymentPaymobApi.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(paymentPaymobApi.fulfilled, (state, action) => {
      state.loading = false;
      if (action.payload.message === "jwt expired") {
        state.error = action.payload.message;
        LogoutFunction();
      }
      if (action.payload.iframe_url) {
        state.url = action.payload;
      } else {
        state.uel = null;
        state.error = action.payload.message;
      }
    });
    builder.addCase(paymentPaymobApi.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload.message;
    });
  },
});

export default paymentPaymobSlicer.reducer;
