import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { ClientJS } from "clientjs";

const generateDeviceId = () => {
  const screenResolution = `${window.screen.width}x${window.screen.height}`;
  const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const language = navigator.language || navigator.userLanguage;
  const deviceType = /Mobi|Tablet|iPad|iPhone|Android/.test(navigator.userAgent)
    ? "Mobile"
    : "Desktop";

  // You can add more factors as needed

  return `${screenResolution}-${timezone}-${language}-${deviceType}-${navigator.platform}`;
};

export const loginUser = createAsyncThunk(
  "loginuser",

  async (userCredintianals) => {
    const client = new ClientJS();
    const uderIdData = generateDeviceId();
    const userDataFromApi = await fetch("https://ipapi.co/json").then((res) =>
      res.json()
    );


    const request = await fetch(
      `${process.env.REACT_APP_VERSEL_API}/api/students/login`,
      {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          email: userCredintianals.email,
          password: userCredintianals.password,
          // fingerprint: {
          //   os: client.getOS(),
          //   browser: client.getBrowser(),
          //   id: client.getFingerprint(),
          // },
          ipData: {...userDataFromApi,uderIdData},
        }),
      }
    )
      .then((response) => response.json())
      .then((result) => {
        if (result.message === "loggedIn") {
          localStorage.setItem("userData", JSON.stringify(result));
        }
        return result;
      })
      .catch((error) => error);
    const response = request;
    return response;
  }
);

const userDataSlicer = createSlice({
  name: "userData",
  initialState: {
    loading: false,
    user: null,
    error: null,
  },

  reducers: {
    Logout(state) {
      state.loading = false;
      state.user = null;
      state.error = null;
      localStorage.removeItem("userData");
    },
  },

  extraReducers: (builder) => {
    builder.addCase(loginUser.pending, (state) => {
      state.loading = true;
      state.user = null;
      state.error = null;
    });
    builder.addCase(loginUser.fulfilled, (state, action) => {
      if (action.payload.message === "loggedIn") {
        state.loading = false;
        state.user = action.payload;
        state.error = null;
      } else {
        state.loading = false;
        state.user = null;
        state.error = action.payload.message;
      }
    });
    builder.addCase(loginUser.rejected, (state, action) => {
      state.loading = false;
      state.user = null;
      state.error = action.payload.message;
    });
  },
});

export default userDataSlicer.reducer;
export const { Logout } = userDataSlicer.actions;
